export const DEFAULT_TOKEN_LIST_URL = 'pancakeswap'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const SAFEMOON_TOKEN_ADDRESS = {
	97: '0x1AD2E11A6f174d92f6f72acE614618092df0d839',
	70: '0x768d221E81524De52841AeD976370b2e4F990416',
}

export const WSAFEMOON_TOKEN_ADDRESS = {
	97: '0x4F7038Ec094041E6E9A8b36717284390381e2F10',
	70: '0x422d0A431D8fb752e3697e90BA04b3324Ea0Cb4a',
}
