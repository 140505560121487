import { MenuEntry } from '../../uikit-foodcourt'

const config: MenuEntry[] = [
	{
		label: 'Home',
		icon: 'HomeIcon',
		href: 'https://www.foodcourt.finance/',
	},
	...(process.env.REACT_APP_ENABLE_DUAL_LAUNCH_UI === 'true'
		? [
				{
					label: 'Dual-Lunch',
					icon: '',
					href: 'https://lunch.foodcourt.finance/',
					subtitle: 'Get COUPON',
				},
		  ]
		: []),
	{
		label: 'Exchange',
		icon: 'TradeIcon',
		href: '/swap',
	},
	{
		label: 'Liquidity',
		icon: 'TradeIcon',
		href: '/pool',
	},
	// {
	// 	label: 'Wrap',
	// 	icon: 'TradeIcon',
	// 	href: '/wrap',
	// },
	{
		label: 'Cafeteria Fl.2',
		icon: 'FarmIcon',
		href: 'https://www.foodcourt.finance/farmsV2',
		subtitle: 'Farm',
	},
	{
		label: 'Snack bar Fl.2',
		icon: 'PoolIcon',
		href: 'https://www.foodcourt.finance/poolsV2',
		subtitle: 'Pool',
	},
	{
		label: 'Bridge',
		icon: '',
		href: 'https://www.northbridge.link',
		subtitle: 'Northbridge',
	},
	// {
	// 	label: 'ILO',
	// 	icon: '',
	// 	href: 'https://biolab.finance',
	// 	subtitle: 'Biolab',
	// },
	// {
	// 	label: 'Fleamarket',
	// 	icon: '',
	// 	href: 'https://fleamarket.foodcourt.finance',
	// 	subtitle: 'Vote my favourite food',
	// },
	// {
	// 	label: 'Supermarket',
	// 	icon: '',
	// 	href: 'https://supermarket.foodcourt.finance',
	// 	subtitle: 'View my wallet',
	// },
	// {
	// 	label: 'Festival 🎉',
	// 	icon: '',
	// 	href: 'https://iff.foodcourt.finance',
	// 	subtitle: 'International Food',
	// },
	// {
	// 	label: 'LuckyLion 🦁',
	// 	icon: 'NftIcon',
	// 	href: 'https://app.luckylion.io/games',
	// 	subtitle: 'Games',
	// },
	// {
	// 	label: 'Closed Farms',
	// 	icon: 'FarmIcon',
	// 	href: 'https://www.foodcourt.finance/farms',
	// 	subtitle: 'Farm',
	// },
	// {
	// 	label: 'Closed Pools',
	// 	icon: 'PoolIcon',
	// 	href: 'https://www.foodcourt.finance/pools',
	// 	subtitle: 'Pool',
	// },
	// {
	// 	label: 'Portfolio',
	// 	icon: 'PredictionsIcon',
	// 	href: 'https://www.foodcourt.finance/portfolio',
	// 	subtitle: 'My portfolio',
	// },
]

export default config
